import React from 'react';
import '@/App.css';
import routes from '@/config/routes';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

export default class App extends React.Component {

  render(): React.ReactNode {
    return (
      <RouterProvider router={createBrowserRouter(routes)} />
    )
  }
}
