import React from "react";
import styles from './index.module.scss'

export function gameBox(): React.ReactElement {
    return (
        <div className={styles.gamebox_body}>
            <img className={styles.title} alt="标题" src={require("@images/gamebox/title.png")} />
            <div className={styles.content}>
                <h2>介绍</h2>
                <p>数元Meta已经在建构自己的游戏平台--数元游戏盒子，数元游戏盒子是基于区块链技术上运行的游戏，游戏中产生的一些物品中每一个都是独特的、不可变的数字资产，具有去中心化、不可更改、所有权证明等特性。数元游戏盒子可以通过智能合约来管理游戏内的物品，并可以实现购买、出售和交换数字资产。让您在参与游戏收获游戏体验的同时运用play to earn的形式让自己获取一定的收益。</p>
            </div>
        </div>
    )
}