import styles from './index.module.scss';
import React from "react";
import { Link } from 'react-router-dom'
// 增加以下代码以使用"自动轮播"功能
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);

export default function Wallet(): React.ReactElement {
    // 注水
    const cooperationsData = [{
        title: '1',
        img: require("@images/wallet/lunbotu/1.png")
    }, {
        title: '2',
        img: require("@images/wallet/lunbotu/2.png")
    }, {
        title: '3',
        img: require("@images/wallet/lunbotu/3.png")
    }, {
        title: '4',
        img: require("@images/wallet/lunbotu/4.png")
    }, {
        title: '5',
        img: require("@images/wallet/lunbotu/5.png")
    }, {
        title: '6',
        img: require("@images/wallet/lunbotu/6.png")
    }, {
        title: '7',
        img: require("@images/wallet/lunbotu/7.png")
    }, {
        title: '8',
        img: require("@images/wallet/lunbotu/8.png")
    }, {
        title: '9',
        img: require("@images/wallet/lunbotu/9.png")
    }, {
        title: '10',
        img: require("@images/wallet/lunbotu/10.png")
    }]

    const groupSize = Math.ceil(cooperationsData.length / 4);
    const cooperations = Array.from({ length: groupSize }, (_, i) => cooperationsData.slice(i * 4, (i + 1) * 4));

    return (
        <div className={styles.wallet_body}>
            <div className={styles.wallet_bg_1}></div>
            <div className={styles.wallet_bg_2}></div>
            <div className={styles.wallet_bg_3}></div>
            {/* <div className={styles.wallet_title}></div> */}

            {/* <section id='wenshujiao' className={styles.wenshujiao}>
                <div className={styles.title}></div>
                <div className={styles.content}>
                    <p>华流元宇宙是数元旗下实物商城（有赞商城），是一个潮流商品发售平台，发售商品将不限于数元Meta数字藏品实物衍生品，潮玩，潮流服饰等商品。目前用户可根据拥有的特定藏品，获得“华流元宇宙”有赞商城的实物商品兑换券/折扣券，用户可凭借兑换券/折扣券“华流元宇宙”有赞商城中购买/兑换相应的商品。除去与数字藏品相关衍生实物藏品以外，还有大量实物商品待上架发售。</p>
                </div>
            </section> */}
            {/* <section id="shuhe" className={styles.shuhe}>
                <div className={styles.title}></div>
                <div className={styles.sub_img_wrapper}>
                    <div className={styles.sub_img}></div>
                </div>
                <div className={`${styles.content} ${styles.staple}`}>
                    <h2>介绍</h2>
                    <p>数元Meta接入数盒钱包，数元Meta的用户可以选择将个人藏品转入数盒钱包中,藏品转入数盒后，藏品将转移至数盒个人钱包中。数盒是在文昌链生态里流通的,数元Meta的用户在数盒中进行交易，则相当于同链交互，用户未来可在生态里进行个人上架寄售等服务。</p>
                </div>
            </section>
            <section id="personal_wallet" className={styles.personal_wallet}>
                <div className={styles.title}></div>
                <div className={`${styles.content} ${styles.staple}`}>
                    <h2>介绍</h2>
                    <p>
                        个人子钱包是一种私人的数字钱包，运用移动应用或网页应用的形式，允许您独立管理您的数字资产，并通过密码加密保护您的资金。这些钱包可以用来存储和转移数字资产，并具有记录资产流动的功能。数元Meta将与许多钱包平台进行合作，发展多款钱包供用户选择，让用户的数字资产变得更加安全可靠。
                    </p>
                </div>
            </section> */}
            <section id="hualiu_meta" className={styles.hualiu_meta}>
                <div className={styles.title}></div>
                <div className={styles.subimg}></div>
                <div className={styles.content}>
                    <h2>跳转链接</h2>
                    <p>
                        <Link to={'https://h5.hualiumeta.com'}>
                            https://h5.hualiumeta.com
                        </Link>
                    </p>
                </div>
            </section>
            <section id="cooperations" className={styles.cooperations}>
                <div className={styles.title}></div>
                <Swiper loop autoplay pagination={{ clickable: true }}>
                    {
                        cooperations.map((a, i) => {
                            return <SwiperSlide key={i}>
                                {
                                    <div className={styles.company}>{
                                        a.map((c, x) => {
                                            return <div key={x} className={styles.img_wrapper}><img className={styles.com_icon} src={c.img} alt={c.title} /></div>
                                        })
                                    }</div>
                                }
                            </SwiperSlide>
                        })
                    }
                </Swiper>
            </section>
        </div>
    )
}