import './index.scss';
import React from "react";

export default function Ipproject(): React.ReactElement {
    const ipprojectArray = [{
        title: '数元编号者',
        type: 'image',
        src: 'https://uthmeta-1300336827.cos.ap-guangzhou.myqcloud.com/1671158069958.jpg',
    }, {
        title: '极限挑战&地狱男爵联名纪念金币',
        type: 'image',
        src: 'https://uthmeta-1300336827.cos.ap-guangzhou.myqcloud.com/1672994737939.jpg',
        target: 'https://www.uthmeta.com/#/pages/nft/nft-details?id=12'
    }, {
        title: '《秋实》',
        type: 'image',
        src: 'https://uthmeta-1300336827.cos.ap-guangzhou.myqcloud.com/1686711331315.gif'
    }, {
        title: '《人世间》',
        type: 'image',
        src: 'https://uthmeta-1300336827.cos.ap-guangzhou.myqcloud.com/1686711247784.gif'
    }, {
        title: '《中国青年》',
        type: 'image',
        src: 'http://shuyuancangpin.oss-cn-hangzhou.aliyuncs.com/upload/20220615/d6675ccff3eecd4a42963c226ab333b3.gif'
    }, {
        title: '《鲁迅系列》',
        type: 'image',
        src: 'http://shuyuancangpin.oss-cn-hangzhou.aliyuncs.com/upload/20220605/9863d1c73675b193b44a5d1d4033bc42.jpeg'
    }, {
        title: '《双饮图》',
        type: 'image',
        src: 'http://shuyuancangpin.oss-cn-hangzhou.aliyuncs.com/upload/20220615/fc279dd78e3bc8a510001c88322ea5d3.gif'
    }, {
        title: '《万年青》',
        type: 'image',
        src: 'http://shuyuancangpin.oss-cn-hangzhou.aliyuncs.com/upload/20220615/cf6439cec9d9887fdbe7b621aa3af3f8.gif'
    }]

    return (
        <div className="ipproject">
            <div className="main_titile">
                <img src={require('@images/ipproject/main_title.png')} alt="标题" />
                <p>数元Meta上线以来，已经发行了《双饮图》 《鲁迅系列》 《人世间》原著8K高清视频、中国风艺术影片《秋实》等数字藏品。未来，我们还将持续发行更多、更有价值的数字藏品。</p>
            </div>
            {
                ipprojectArray.map(project => {
                    return (
                        <div key={project.title} className='project_item'>
                            <div className='border_wrapper'>
                                {
                                    project.type === 'image' ? <img src={project.src} alt={project.title} /> : <video muted={true} autoPlay={true} src={project.src} />
                                }
                                <div className='title'>
                                    <h2>{project.title}</h2>
                                    <div>
                                        <button onClick={() => {if(project.target) window.location.href = project.target}}>查看简介</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}