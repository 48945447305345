import './index.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import React, { useEffect, useState  } from 'react'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/effect-fade/effect-fade.min.css';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

// 增加以下代码以使用"自动轮播"功能
// import SwiperCore, { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
// SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);

export default function Index(): React.ReactElement {
  const navigate = useNavigate()
  const [collectionList, setCollectiobList] = useState([]);

  const projectList = [{
    iconName: 'meta',
    name: 'IP项目',
    path: '/ipproject',
    url: 'https://collection.uthmeta.com/#/',
    infomation: '数元Meta上线以来，已经发行了《双饮图》 《鲁迅系列》 《人世间》原著8K高清视频、中国风艺术影片《秋实》等数字藏品。未来，我们还将持续发行更多、更有价值的数字藏品。'
  }, {
    iconName: 'hualiu',
    name: '华流元宇宙',
    path: '/hualiumeta',
    url: 'https://shop110508527.m.youzan.com/v2/showcase/homepage?alias=1ffVcOooJw&spm=m1075799136553402368.autoreply&yz_from=yz_wx_reply&shopAutoEnter=1&is_silence_auth=1',
    infomation: '华流元宇宙是数元旗下实物商城（有赞商城），是一个潮流商品发售平台，发售商品将不限于数元Meta数字藏品实物衍生品，潮玩，潮流服饰等商品。'
  }, {
    iconName: 'dao',
    name: '应用工具',
    path: '/dao',
    infomation: '数元S-Dao.是探索营里的先锋阵营，让文化实现和虚拟世界的联动，利用对未知空间的探索，延伸现实世界的认知，将传统文化与现代潮流艺术结合，带来不一样的价值体验。'
  }, {
    iconName: 'box',
    name: '游戏盒子',
    path: '/gamebox',
    infomation: '数元游戏盒子是基于区块链技术上运行的游戏，运用Play to Earn的形式让用户获取一定的收益。数元游戏盒子可以通过智能合约来管理游戏内的物品，并可以实现购买、出售和交换数字资产。'
  }]

  const membarList = [{
    avater: require('@/assets/images/avater/avater_chen.png'),
    userName: '陈权念'
  }, {
    avater: require('@/assets/images/avater/avater_yang.png'),
    userName: '杨新哲'
  }, {
    avater: require('@/assets/images/avater/avater_zheng.png'),
    userName: '郑颖'
  }, {
    avater: require('@/assets/images/avater/avater_huang.png'),
    userName: '黄志龙'
  }]

  const openUrl = function (path, url) {
    // const webReg = /^https?:\/\//;
    url ? window.location.href = url : navigate(path);
  }

  useEffect(() => {
    axios.get('https://api.uthmeta.com/meta/category/getRecentCategoryList')
      .then(response => {
        console.log('data', response.data.data)
        setCollectiobList(response.data.data);
      });
  }, []);

  return (
    <div className='index_body'>
      <div className='banners'>
          <div>
            <img src={require('@/assets/images/banner0.png')} className="banner" />
            <div className='banner-info info2'>
              <div className='title'>数元Meta website.</div>
              <div className='content'>
                <p>平台介绍：</p>
                「数元Meta」数字藏品赋能发行平台隶属于中普数元（厦门）数字科技有限公司，平台对接“文昌链”，是基于国家级区块链BSN基础网络的开放联盟链。平台对上线藏品实行严格的筛选机制，发行方需具备清晰完整的版权归属，并实行“限量发行、区块链确权、独一无二等”方式，让藏品具备更高、更稀缺性的收藏及欣赏价值。 数元Meta上线以来，已经发行了《双饮图》 《鲁迅系列》 《人世间》原著8K高清视频、中国风艺术影片《秋实》等数字藏品。未来，我们还将持续发行更多、更有价值的数字藏品。 「数元Meta」将秉承着复兴传统文化、赋能实体经济的信念，保持初心，一路向前。
              </div>
            </div>
          </div>
        {/* <Swiper loop autoplay pagination={{ clickable: true }}>
          <SwiperSlide>
            <div>
              <img src={require('@/assets/images/banner0.png')} className="banner" />
              <div className='banner-info'>
                <div className='title'>数元Meta website.</div>
                <div className='content'>
                  <p>公司介绍：</p>
                  中普数元（厦门）数字科技有限公司，是由美股上市企业普普文化及福建省文化龙头企业中视传播共同出资成立，旨探讨在数字化时代，多元化内容与科技深入融合，探索元宇宙大未来。坚持为传统文化和实体产业赋能，助推产业经济数字化，打造“前瞻性、联合性”的元宇宙产业集群，布局具有中国特色的元宇宙大生态。
                  <img src={require('@/assets/images/logo-zhongpu.png')} className='banner-picture' />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <img src={require('@/assets/images/banner0.png')} className="banner" />
              <div className='banner-info info2'>
                <div className='title'>数元Meta website.</div>
                <div className='content'>
                  <p>平台介绍：</p>
                  「数元Meta」数字藏品赋能发行平台隶属于中普数元（厦门）数字科技有限公司，平台对接“文昌链”，是基于国家级区块链BSN基础网络的开放联盟链。平台对上线藏品实行严格的筛选机制，发行方需具备清晰完整的版权归属，并实行“限量发行、区块链确权、独一无二等”方式，让藏品具备更高、更稀缺性的收藏及欣赏价值。 数元Meta上线以来，已经发行了《双饮图》 《鲁迅系列》 《人世间》原著8K高清视频、中国风艺术影片《秋实》等数字藏品。未来，我们还将持续发行更多、更有价值的数字藏品。 「数元Meta」将秉承着复兴传统文化、赋能实体经济的信念，保持初心，一路向前。
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper> */}
      </div>

      <div className='project-team'>
        <Swiper slidesPerView={2}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            disabledClass: 'swiper-btn-disabled'
          }}
          spaceBetween={-16}
        >
          {
            projectList.map(item => {
              return (
                <SwiperSlide key={item.iconName}>
                  <div className='project-item' onClick={() => openUrl(item.path, item.url)}>
                  {/* <div className='project-item' onClick={() => navigate(item.url ? window.location.href = item.url : item.path)}> */}
                    <img src={require(`@/assets/images/product-${item.iconName}.png`)} />
                    <div>{item.name}</div>
                  </div>
                </SwiperSlide>
              )
            })
          }
          <div className="swiper-button-prev">
            <img src={require('@/assets/images/icon-direction-left.png')} />
          </div>
          <div className="swiper-button-next">
            <img src={require('@/assets/images/icon-direction-right.png')} />
          </div>
        </Swiper>
      </div>

      <div className='project-infomation'>
        {
          projectList.map(item => {
            return (
              <div className='project-item' key={item.name} onClick={() => navigate(item.path)}>
                <img src={require(`@/assets/images/product-${item.iconName}.png`)} />
                <div className='name'>{item.name}</div>
                <div className='infomation'>{item.infomation}</div>
              </div>
            )
          })
        }
      </div>

      <div className='collection'>
        <div className='title-tip'>系列藏品</div>
        <div className='title'>数元近期项目</div>
        <div className='swiper'>
          <Swiper
            className='swiper-box'
            autoplay
            pagination={{ clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              disabledClass: 'swiper-btn-disabled'
            }}
          >
            {
              collectionList.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className='collection-item'>
                      <img className='collection-img' alt={item.content} src={item.mainphoto} />
                      <div className='callection-name'>{item.name}</div>
                      <a className='collection-btn' href={item.link}>点击跳转</a>
                    </div>
                  </SwiperSlide>
                )
              })
            }
            <div className="swiper-button-prev">
              <img alt='上一张' src={require('@/assets/images/icon-direction-left.png')} />
            </div>
            <div className="swiper-button-next">
              <img alt='下一张' src={require('@/assets/images/icon-direction-right.png')} />
            </div>
          </Swiper>
        </div>
        <div className='look-over'>点击查看藏品</div>
      </div>

      <div className='activitys'>
        <a href='https://mp.weixin.qq.com/s/aQ5pVwozEwFcKEMZNTqREA' className='text-dere'>
          <div className='activity-item' >
            <img alt='猴赛雷' className='tweet-img' src={require('@/assets/images/tweet1.png')} />
            <div className='activity-content'>
              <div className='content'>“猴赛雷”即将开启DIY， 创造属于你独一无二的专属数字藏品</div>
              <div className='look'>查看</div>
            </div>
          </div>
        </a>
        <a href='https://mp.weixin.qq.com/s/r0YDsfN2-Nxw0OyVNWSguA' className='text-dere'>
          <div className='activity-item'>
            <img alt='无聊猿' className='tweet-img' src={require('@/assets/images/tweet0.png')} />
            <div className='activity-content'>
              <div className='content'>BAYC#7963 无聊猿加入【数元Meta】，用“无聊”开启更多可能！</div>
              <div className='look'>查看</div>
            </div>
          </div>
        </a>

      </div>

      <div className='members'>
        <div className='title-tip'>团队成员</div>
        <div className='member-list'>
          {
            membarList.map((item, index) => {
              return (
                <div key={index} className='member-item'>
                  <img alt={item.userName} src={item.avater} />
                  <div>{item.userName}</div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

