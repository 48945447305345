import React from 'react';
import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

export default function ScrollToTop(props: React.PropsWithChildren): React.ReactElement {
    const { pathname } = useLocation();

    useEffect(() => {
        const hash = window.location.hash
        if (hash) {
            document.querySelector(hash).scrollIntoView({ behavior: 'smooth' })
        } else {
            window.scrollTo(0, 0)
        }
    }, [pathname]);

    return <>
        {props.children}
    </>
}