import React from "react";
import styles from './index.module.scss';

export function hualiumeta(): React.ReactElement {
    return (
        <div className={styles.hualiumeta_body}>
            <div className={styles.content}>
                华流元宇宙是数元旗下实物商城（有赞商城），是一个潮流商品发售平台，发售商品将不限于数元Meta数字藏品实物衍生品，潮玩，潮流服饰等商品。目前用户可根据拥有的特定藏品，获得“华流元宇宙”有赞商城的实物商品兑换券/折扣券，用户可凭借兑换券/折扣券“华流元宇宙”有赞商城中购买/兑换相应的商品。除去与数字藏品相关衍生实物藏品以外，还有大量实物商品待上架发售。
            </div>
        </div>
    )
}