import { RouteObject, Navigate } from "react-router-dom";
import Layout from "@/view/layout";
import Index from '@/view/index'
import Wallet from '@/view/wallet'
import React from "react";
import Ipproject from "@/view/ipproject";
import { hualiumeta } from "@/view/hualiumeta";
import { gameBox } from "@/view/gamebox";
import dao from "@/view/dao";
import diytool from "@/view/diytool";

const routes: Array<RouteObject> = [{
    path: '/',
    Component: Layout,
    children: [ {
        path: '/',
        Component: Index
    }, {
        path: '/index',
        Component: () => Navigate({ to: '/' })
    }, {
        path: '/wallet',
        Component: Wallet
    }, {
        path: '/ipproject',
        Component: Ipproject
    }, {
        path: '/hualiumeta',
        Component: hualiumeta
    }, {
        path: '/gamebox',
        Component: gameBox
    }, {
        path: '/dao',
        Component: dao
    }, {
        path: '/diytool',
        Component: diytool
    }]
}, {
    path: '*',
    Component: () => React.createElement('h1', null, '404')
}]

export default routes