import React from "react";
import styles from './index.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useNavigate } from "react-router-dom";

export default function Dao(): React.ReactElement {
    const navigate = useNavigate()

    const swipers = [{
        title: "身份识别社区",
        content: "根据用户持有的藏品进行身份识别，作用类似于钥匙，用于进入特定的社区以及进行集体决策。",
        img: require(`@images/dao/yaoshi.png`)
    }, {
        title: "身份识别社区",
        content: "根据用户持有的藏品进行身份识别，作用类似于钥匙，用于进入特定的社区以及进行集体决策。",
        img: require(`@images/dao/yaoshi.png`)
    }]

    return (
        <div className={styles.dao_body}>
            <div className={styles.topbar}>
                <div className={styles.main_title}>
                    <img src={require("@images/dao/main_title.png")} alt="标题" />
                </div>
                <div className={styles.bar_btns}>
                    <div onClick={() => navigate("/dao")} className={styles.dao}>
                        <img alt="dao" src={require("@images/dao/dao.png")} />
                    </div>
                    <div onClick={() => navigate("/diytool")} className={styles.diy}>
                        <img alt="diy" src={require("@images/dao/diy.png")} />
                    </div>
                </div>
            </div>
            <div className={styles.introduction}>
                <div className={styles.wrapper}>
                    <h1>Tool introduction</h1>
                    <h2>DAO工具介绍</h2>
                    <p>数元S-Dao.是探索营里的先锋阵营，让文化实现和虚拟世界的联动，利用对未知空间的探索，延伸现实世界的认知，将传统文化与现代潮流艺术结合，带来不一样的价值体验。</p>
                    <div className={styles.card}>
                        <img alt="介绍" src={require("@images/dao/introduction.png")} />
                        <h2>基本模块包括</h2>
                        <div className={styles.content}>
                            <p>身份识别社区、投票......</p>
                            <a href="https://dao.uthmeta.com/#/">查看</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.module}>
                <div className={styles.title}>
                    <img className={styles.img100} alt="模块介绍" src={require("@images/dao/module_title.png")} />
                </div>
                <Swiper
                    className={styles.swiper_box}
                    autoplay
                    pagination={{ clickable: true }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                        disabledClass: 'swiper-btn-disabled'
                    }}
                >
                    {
                        swipers.map((swiper, Index) => {
                            return (
                                <SwiperSlide key={Index}>
                                    <div className={styles.collection_item}>
                                        <img className={styles.img100} alt="钥匙" src={swiper.img} />
                                        <div className={styles.content}>
                                            <h1>{swiper.title}</h1>
                                            <p>{swiper.content}</p>
                                        </div>
                                    </div>
                                    <div className={styles.collection_btn}><h3>进入查看</h3></div>
                                </SwiperSlide>
                            )
                        })
                    }
                    <div className="swiper-button-prev">
                        <img alt='上一张' src={require('@/assets/images/icon-direction-left.png')} />
                    </div>
                    <div className="swiper-button-next">
                        <img alt='下一张' src={require('@/assets/images/icon-direction-right.png')} />
                    </div>
                </Swiper>
            </div>
        </div>
    )
}