import React, { useState } from "react";
import styles from './index.module.scss'
import { useNavigate } from "react-router-dom";

export default function Diytool(): React.ReactElement {
    const navigate = useNavigate()
    const [previewIndex, setPreviewIndex] = useState(0);

    const numbers = Array.from({ length: 16 }, (_, i) => i + 1);
    const diys = numbers.reduce((result, _, i) => {
        if (i % 4 === 0) {
            result.push(numbers.slice(i, i + 4));
        }
        return result;
    }, [])

    function randomDiy(): void {
        const randomInteger = Math.floor(Math.random() * 30) + 1;
        setPreviewIndex(randomInteger)
    }

    return (
        <div className={styles.diytool_body}>
            <div className={styles.topbar}>
                <div className={styles.bar_btns}>
                    <div onClick={() => navigate("/dao")} className={styles.dao}>
                        <img alt="dao" src={require("@images/dao/dao.png")} />
                    </div>
                    <div onClick={() => navigate("/diytool")} className={styles.diy}>
                        <img alt="diy" src={require("@images/dao/diy.png")} />
                    </div>
                </div>
            </div>
            <div className={styles.function}>
                <div className={styles.wrapper}>
                    <h1>DIY tools</h1>
                    <h2>DIY工具介绍</h2>
                    <p>头像DIY功能的开发旨在为元宇宙的发展提供了更大的想象空间，让每个人都能拥有自己创作的数字藏品。用户可按审美、需求，根据平台提供的预设元素进行创作，铸造出独一无二的藏品，建立藏品差异化。</p>
                    <div className={styles.card}>
                        <img alt="介绍" src={require("@images/dao/introduction.png")} />
                        <h2>功能区包括</h2>
                        <div className={styles.content}>
                            <p>自由根据部位选择搭配.....</p>
                            <a href="https://collection.uthmeta.com/#/pages/head-diy/head-diy">查看</a>
                        </div>
                    </div>
                </div>
                <div className="title">
                    <img src={require("@images/diy/function_title.png")} alt="title" />
                </div>
                <div className={styles.preview}>
                    <div className={styles.leftitem}>
                        <img alt="left" src={require("@images/diy/gray.png")} />
                    </div>
                    <div className={styles.rightitem}>
                        <img alt="right" src={require("@images/diy/cheng.png")} />
                    </div>
                    <div className={styles.mainitem}>
                        <img alt="right" src={require(`@images/diy/generate/${previewIndex}.png`)} />
                    </div>
                </div>
                <div className={styles.random_btn}>
                    <img onClick={randomDiy} alt="随机按钮" src={require("@images/diy/random_btn.png")} />
                </div>
            </div>

            <div className={styles.display}>
                {
                    diys.map((d, index) => {
                        return <div key={d} className={`${styles.rows} ${index % 2 ? styles.left : styles.right}`}>
                            {
                                d.map((o: React.Key) => {
                                    return <img key={o} alt={o + ""} src={require(`@images/diy/generate/${o}.png`)} />
                                })
                            }
                            {
                                d.map((o: React.Key) => {
                                    return <img key={o} alt={o + ""} src={require(`@images/diy/generate/${o}.png`)} />
                                })
                            }
                        </div>
                    })
                }
                <div className={styles.shadow}/ >
            </div>
        </div>
    )
}