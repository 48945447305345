/*
 * @Author: 谭小成Tan_BC 1443400951@qq.com
 * @Date: 2023-06-13 10:42:23
 * @LastEditors: 谭小成Tan_BC 1443400951@qq.com
 * @LastEditTime: 2023-08-11 17:24:00
 * @FilePath: \meta-officesite\src\view\layout.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ScrollToTop from '@/components/scroll_to_top';
import './layout.scss'
import React, { useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";

export default function Layout(): React.ReactElement {
    const navigate = useNavigate();

    const [asideFlag, setAsideFlag] = useState(false)

    const triggerTab = (): void => {
        setAsideFlag(!asideFlag)
    }

    const navigateHandler = (url: string) => {
        setAsideFlag(false); // 关闭下拉列表
        const webReg = /^https?:\/\//; // 网站前缀正则验证 选择跳转外链还是本地路由
        webReg.test(url) ? window.location.href = url : navigate(url);
    }

    const scrollBottom = () => {
        setAsideFlag(false)
        document.querySelector('.footer').scrollIntoView({behavior: 'smooth'})
    }

    return (
        <ScrollToTop>
            <div className='meta_body'>
                <div className={`${asideFlag ? 'mask' : ''}`} onClick={triggerTab} />
                <header className='header_wrapper'>
                    <div className={`${asideFlag ? '' : 'aside-close'} aside`}>
                        <div className='head'>
                            <img onClick={() => navigateHandler("/")} src={require('@/assets/images/icon-logo.png')} className='logo' />
                            <img onClick={triggerTab} src={require('@/assets/images/switch-sidebar.png')} className='sidebar' />
                        </div>
                        <div className='aside-list'>
                            <div className='navbar'>
                                <div className='navbar-title'>特色功能</div>
                                <ul className='secondary-navbar'>
                                    <li onClick={() => navigateHandler("https://collection.uthmeta.com/#/")}>数字藏品</li>
                                    <li onClick={() => navigateHandler("https://shop110508527.m.youzan.com/v2/showcase/homepage?alias=1ffVcOooJw&spm=m1075799136553402368.autoreply&yz_from=yz_wx_reply&shopAutoEnter=1&is_silence_auth=1")}>商城</li>
                                    <li onClick={() => navigateHandler("https://collection.uthmeta.com/#/pages/my/task-list")}>任务管理</li>
                                    <li onClick={() => navigateHandler("dao")}>娱乐互动</li>
                                    <li>AI</li>
                                </ul>
                                {/* <ul className='secondary-navbar'>
                                    <li onClick={() => navigateHandler("wallet#wenshujiao")}>湖南文数交</li>
                                    <li onClick={() => navigateHandler("wallet#shuhe")}>数盒</li>
                                    <li onClick={() => navigateHandler("wallet#personal_wallet")}>个人子钱包</li>
                                </ul> */}
                            </div>
                            <div className='navbar'>
                                <div className='navbar-title' onClick={() => navigateHandler("wallet#hualiu_meta")}>华流Meta版权图库</div>
                            </div>
                            <div className='navbar'>
                                <div className='navbar-title' onClick={() => navigateHandler("wallet#cooperations")}>部分合作单位</div>
                            </div>
                            <div className='navbar'>
                                <div className='navbar-title' onClick={scrollBottom}>合作咨询</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${asideFlag ? 'close' : ''} head`}>
                        <img onClick={() => navigateHandler("/")} src={require('@/assets/images/icon-logo.png')} className='logo' />
                        <img onClick={triggerTab} src={require('@/assets/images/switch-sidebar.png')} className='sidebar' />
                    </div>
                </header>
                <Outlet />
                <footer className='footer'>
                    <img className='footer-logo' src={require('@/assets/images/icon-logo.png')} />
                    <div className='contact-tip'>关注或联系我们</div>
                    <div className='concact-text'>公众号二维码、加入社群、微博二维码</div>
                    <div className='qrcodes'>
                        <img src={require('@/assets/images/qrcode-association.png')} />
                        <img src={require('@/assets/images/qrcode-meta.png')} />
                        <img src={require('@/assets/images/qrcode-weblog.png')} />
                    </div>
                    {/* <div className='footer-content'>联系方式：15750741527</div> */}
                    <div className='footer-content'>官方邮箱：695290628@qq.com</div>
                    <div className='footer-content'>数元Meta平台链接：https://collection.uthmeta.com/#/</div>
                    <div className='footer-content'>公司地址：厦门火炬高新区软件园三期凤岐路168号303室之三</div>
                    <div className='footer-content'><a href="https://beian.miit.gov.cn/" target="_blank">备案号：闽ICP备2021018487号-4</a></div>
                    <div className='footer-content'>uthmeta.com (C) 2022. All right reserved</div>
                </footer>
            </div>
        </ScrollToTop>
    )
}